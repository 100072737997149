/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import cn from 'classnames';
import { handleBack } from 'core-lib/utils/common';
import { isParkPlusWebView } from 'core-lib/utils/source';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TitleBar from 'core-lib/components/common/TitleBar';
import FinancialProductSchema from 'src/utils/schema/financialProductSchema';
import ParkingFacility from 'src/utils/schema/parkingSchema';
import SiteNavigationElement from 'src/utils/schema/siteNavigationSchema';
import TableSchema from 'src/utils/schema/tableSchema';
import { navList } from 'core-lib/data/common';
import { isWebView } from 'core-lib/utils/helpers';
import AuthorSchema from 'src/utils/schema/authorSchema';
import BlogArticle from 'src/utils/schema/blogArticleSchema';
import ItemListSchemaNew from 'src/utils/schema/itemListSchemaNew';
import NewsArticle from 'src/utils/schema/newsArticleSchema';
import VehicleSchema from 'src/utils/schema/vehicleSchema';
import Footer from '../../../../common/components/Footer';
import Subheader from '../../../../common/components/Subheader';
import MobileApplicationSchema from '../../../../utils/schema/mobileApplication';
import BreadCrumb from '../../bread_crumbs/bread_crumbs';
import BreadcrumbV1 from '../../bread_crumbs_v2/bread_crumbs_v2.tsx';
import HeaderV1 from '../../headerv1/headerv1';
import NavBar from '../../navbar/navbar';
import PageHeaderV1 from '../../page_header_v1';
import s from './navbar_layout_v1.module.scss';
import BreadCrumbV2 from '../../bread_crumbs_v2/bread_crumbs_v2.tsx';
function NavBarLayoutV1({
  children,
  title,
  header,
  footer,
  breadCrumb,
  navBar,
  pageHeader,
  customStyle,
  showProfile,
  showContact,
  isExpanded,
  subHeader,
  mobileApplicationSchema,
  siteNavigationSchema,
  tableSchema,
  parkingSchema,
  financialSchema,
  // howToSchema,
  newsArticle,
  blogArticle,
  authorSchema,
  vehicleSchema,
  itemListSchemaNew,
  newBreadCrumb = false
}) {
  const [isWeb, setIsWebview] = useState(false);
  const router = useRouter();
  const src = router?.query?.source;
  useEffect(() => {
    if (
      (typeof src === 'string' &&
        ['androidparkplus']?.includes(src?.toLowerCase())) ||
      isParkPlusWebView() ||
      isWebView()
    ) {
      setIsWebview(true);
    }
  }, [router]);

  return (
    <div className={s.navbar_layout_v1} style={customStyle}>
      {header.show && !isWeb ? (
        <div className={cn('cmn_only_desktop', s.headerSticky)}>
          <HeaderV1
            activeLink={header.activeLink}
            showProfile={showProfile}
            showContact={showContact}
            isExpanded={isExpanded}
          />
        </div>
      ) : (
        <div className="cmn_hide" />
      )}
      {pageHeader.show ? (
        <>
          {!isWeb ? (
            <div className={cn('cmn_only_mobile')}>
              <PageHeaderV1
                showBackBtn={pageHeader.backBtn.show}
                onBackBtnClick={pageHeader.backBtn.onClick}
                showProfile={pageHeader.showProfile}
                showMenu={pageHeader.showMenu}
              />
            </div>
          ) : (
            <TitleBar onBack={handleBack} />
          )}
        </>
      ) : (
        <div className="cmn_hide" />
      )}
      {subHeader.show ? (
        <Subheader tabs={subHeader.tabs} />
      ) : (
        <div className="cmn_hide" />
      )}
      {title !== '' && <div className={s.head}>{title}</div>}


      <div className={s.body}>{children}</div>

      {breadCrumb.show && !isWeb && (newBreadCrumb ? <BreadCrumbV2 data={breadCrumb?.data} /> : <BreadCrumb data={BreadCrumb?.data} />)}

      {footer.show && !isWeb ? <Footer /> : <div className="cmn_hide" />}
      {navBar.show ? (
        <div className={cn('cmn_only_mobile', s.navbar)}>
          <NavBar navList={navList} />
        </div>
      ) : (
        <div className="cmn_hide" />
      )}
      {mobileApplicationSchema.show ? (
        <MobileApplicationSchema />
      ) : (
        <div className="cmn_hide" />
      )}
      {siteNavigationSchema.show ? (
        <SiteNavigationElement data={siteNavigationSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {parkingSchema.show ? (
        <ParkingFacility data={parkingSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {tableSchema?.show ? (
        <TableSchema data={tableSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {financialSchema?.show ? (
        <FinancialProductSchema data={financialSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {/* {howToSchema?.show ? (
        <HowToSchema data={howToSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )} */}
      {newsArticle?.show ? (
        <NewsArticle data={newsArticle?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {blogArticle?.show ? (
        <BlogArticle data={blogArticle?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {authorSchema?.show ? (
        <AuthorSchema data={authorSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {vehicleSchema?.show ? (
        <VehicleSchema data={vehicleSchema?.data} />
      ) : (
        <div className="cmn_hide" />
      )}
      {itemListSchemaNew?.show ? (
        <ItemListSchemaNew data={itemListSchemaNew?.data} />
      ) : (
        <div className="cmm_hide" />
      )}
    </div>
  );
}

NavBarLayoutV1.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  header: PropTypes.shape({
    show: PropTypes.bool,
    activeLink: PropTypes.string,
  }),
  footer: PropTypes.shape({
    show: PropTypes.bool,
  }),
  navBar: PropTypes.shape({
    show: PropTypes.bool,
  }),
  mobileApplicationSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.string,
  }),
  siteNavigationSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  tableSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  breadCrumb: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }),
  parkingSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  financialSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  newsArticle: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  blogArticle: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  authorSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  // howToSchema: {
  //   show: PropTypes.bool,
  //   data: PropTypes.object,
  // },
  vehicleSchema: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  itemListSchemaNew: PropTypes.shape({
    show: PropTypes.bool,
    data: PropTypes.object,
  }),
  downloadBanner: PropTypes.shape({
    show: PropTypes.bool,
  }),
  pageHeader: PropTypes.shape({
    show: PropTypes.bool,
    title: PropTypes.string,
    backBtn: PropTypes.shape({
      show: PropTypes.bool,
      onClick: PropTypes.func,
    }),
    showProfile: PropTypes.bool,
    showMenu: PropTypes.bool,
  }),
  customStyle: PropTypes.objectOf(PropTypes.any),
  showProfile: PropTypes.bool,
  showContact: PropTypes.bool,
  isExpanded: PropTypes.bool,
  subHeader: PropTypes.shape({
    show: PropTypes.bool,
    tabs: PropTypes.arrayOf(PropTypes.any),
  }),
};

NavBarLayoutV1.defaultProps = {
  title: '',
  header: {
    show: false,
    activeLink: null,
  },
  footer: {
    show: false,
  },
  navBar: {
    show: false,
  },
  mobileApplicationSchema: {
    show: false,
  },
  siteNavigationSchema: {
    show: false,
    data: {},
  },
  tableSchema: {
    show: false,
    data: {},
  },
  // howToSchema: {
  //   show: false,
  //   data: {},
  // },
  breadCrumb: {
    show: false,
    data: [], authorSchema: {
      show: false,
      data: {},
    },
  },
  newsArticle: {
    show: false,
    data: {},
  },
  authorSchema: {
    show: false,
    data: {},
  },
  vehicleSchema: {
    show: false,
    data: {},
  },
  blogArticle: {
    show: false,
    data: {},
  },
  parkingSchema: {
    show: false,
    data: {},
  },
  financialSchema: {
    show: false,
    data: {},
  },
  itemListSchemaNew: {
    show: false,
    data: {},
  },
  pageHeader: {
    show: false,
    title: '',
    backBtn: {
      show: false,
      onClick: () => { },
    },
    showProfile: false,
    showMenu: false,
  },
  customStyle: null,
  showProfile: true,
  showContact: false,
  isExpanded: true,
  subHeader: {
    show: false,
    tabs: [],
  },
};

export default connect(null, null)(NavBarLayoutV1);
